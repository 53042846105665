function deepCopy(data) {
    // 判断是否是对象
    if (typeof data !== 'object') return data;
    // 判断obj类型，根据类型新建一个对象或者数组
    var newData = data instanceof Array ? [] : {};
    // 遍历对象，进行赋值
    for (var key in data) {
        if (data.hasOwnProperty(key)) {
            let val = data[key];
            // 判断属性值的类型，如果是对象，递归调用deepCopy
            newData[key] = typeof val === 'object' ? deepCopy(val) : val;
        }
    }
    return newData;
}

/**
 * 格式化字节大小
 * @param 大小
 * @param 保留小数  默认2
 * @returns {string}
 */
function formatBytesSize(size, decimal) {
    if (0 == size) return '0 B';
    var c = 1024,
        d = decimal || 2,
        e = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        f = Math.floor(Math.log(size) / Math.log(c));
    return parseFloat((size / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
}

const toolsPlugin = {
    install(app) {
        // 定义你的自定义方法
        app.config.globalProperties.$tools = {
            deepCopy,
            formatBytesSize,
        };
    },
};
export default toolsPlugin;
