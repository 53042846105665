<script>
import cache from '@/utils/cache';
export default {
    name: 'public-im-contact',
    components: {},
    props: {
        msg: String,
    },
    data() {
        return {
            search: {
                keyword: '',
            },
            topList: [],
            mainList: [],
        };
    },
    created: function () {},
    mounted: function () {
        let self = this;
        self.topList = [
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
        ];
        self.mainList = [
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
            {
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            },
        ];
    },
    methods: {
        updateScrollbar() {
            let self = this;
            console.log('updateScrollbar');
            self.$nextTick(() => {
                self.$refs.contactListScrollbarRef.update();
            });
        },
        pushContact() {
            let self = this;
            this.mainList.push({
                avatar: '',
                name: '张三',
                message: '这是最后一条消息, 点开看看吧',
                timeStr: '今天',
            });
        },
    },
};
</script>

<template>
    <div class="body">
        <div class="search">
            <div class="input">
                <div class="icon">
                    <el-icon size="16">
                        <Search />
                    </el-icon>
                </div>
                <input v-model="search.keyword" />
                <div v-if="search.keyword" class="icon">
                    <el-icon size="16">
                        <Close />
                    </el-icon>
                </div>
            </div>
            <div class="btn-plus" @click="pushContact">
                <el-icon size="16">
                    <Plus />
                </el-icon>
            </div>
        </div>
        <div class="list">
            <el-scrollbar ref="contactListScrollbarRef">
                <template v-for="(contact, c) in topList" :key="c">
                    <div class="contact top">
                        <div class="avatar">
                            <img :src="contact.avatar" />
                        </div>
                        <div class="info">
                            <div class="title">
                                <div class="name">{{ contact.name }}</div>
                                <div class="time">{{ contact.timeStr }}</div>
                            </div>
                            <div class="message">
                                <div>{{ contact.message }}</div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-for="(contact, c) in mainList" :key="c">
                    <div class="contact">
                        <div class="avatar">
                            <img :src="contact.avatar" />
                        </div>
                        <div class="info">
                            <div class="title">
                                <div class="name">{{ contact.name }}</div>
                                <div class="time">{{ contact.timeStr }}</div>
                            </div>
                            <div class="message">
                                <div>{{ contact.message }}</div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-scrollbar>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.body {
    height: 100%;
    display: flex; /* 使用 Flexbox 布局 */
    flex-direction: column; /* 纵向排列 */

    .search {
        height: 64px;
        border-bottom: 1px solid #ddd;
        background-color: #f7f7f7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;

        .input {
            width: 200px;
            margin-left: 10px;
            height: 28px;
            border-radius: 4px;
            border: 0;
            overflow: hidden;
            display: flex;
            align-items: center;
            background-color: #ddd;

            .icon {
                width: 24px;
                display: grid;
                place-items: center;
                flex-shrink: 0;
                cursor: pointer;
            }

            input {
                outline: none;
                border: 0;
                height: 28px;
                width: 100%;
                background-color: transparent;
            }
        }

        .btn-plus {
            margin-right: 10px;
            margin-left: 10px;
            height: 28px;
            width: 28px;
            border-radius: 4px;
            background-color: #ddd;
            flex-shrink: 0;
            display: grid;
            place-items: center;
            cursor: pointer;
        }
    }

    .list {
        flex: 1;
        overflow: hidden; /* 如果内容溢出，显示滚动条 */
        .contact {
            height: 40px;
            display: flex;
            padding: 12px;
            justify-content: space-between;
            align-items: center;
            user-select: none;

            &.top {
                background-color: #dfdddd;
            }

            &:hover {
                background-color: #d7d4d3;
            }

            .avatar {
                background-color: #000;
                width: 40px;
                height: 40px;
                border-radius: 4px;
                margin-right: 10px;
                flex-shrink: 0;
            }

            .info {
                flex: 1;

                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .name {
                        font-size: 16px;
                    }

                    .time {
                        font-size: 12px;
                        color: #999;
                    }
                }

                .message {
                    margin-top: 3px;
                    display: flex;
                    justify-content: space-between;
                    font-size: 12px;
                    color: #999;
                }
            }
        }

        .clearfix::after {
            content: ' ';
            display: block;
            clear: both; /* 清除浮动 */
        }
    }
}
</style>
