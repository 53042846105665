<script>
export default {
    name: 'public-menu',
    components: {},
    props: {
        msg: String,
    },
    created() {},
    data() {
        return {
            currentPath: '',
            menuList: [],
            mainMenu: [],
            location: 'location',
            isCollapse: false,
            subMenuOpenedState: true,
            subMenuWebNameState: true,
            icon: 'edit',
            curTab: {},
            tabList: [],
        };
    },
    created: function () {
        let self = this;
        self.getMenuInfo();
    },
    watch: {
        $route(to, from) {
            let self = this;
            self.currentPath = '/#' + to.path;
        },
    },
    methods: {
        goHistory: function () {},
        delHistory: function () {},
        changeMenu: function (mainMenu) {
            this.mainMenu = mainMenu;
            this.subMenu = mainMenu.subMenu;
            for (let i in this.menuList) {
                if (mainMenu.id == this.menuList[i]['id']) {
                    this.menuList[i]['selected'] = true;
                } else {
                    this.menuList[i]['selected'] = false;
                }
            }
        },
        updateInfo: function () {
            let self = this;
            self.subMenuOpenedState = !self.subMenuOpenedState;
            if (self.subMenuOpenedState) {
                setTimeout(() => {
                    self.subMenuWebNameState = true;
                }, 300);
            } else {
                self.subMenuWebNameState = false;
            }
        },
        //刷新当前页
        refreshCurrent() {
            let self = this;
            self.curTab.number = self.curTab.number + 1;
            for (let i in self.tabList) {
                if (self.tabList[i]['path'] == self.curTab.path) {
                    self.tabList[i]['number'] = self.curTab.number;
                }
            }
        },
        testPage: function (tab) {
            let self = this;
            if (tab.path == self.curTab.path) {
                return self.refreshCurrent();
            }
            self.curTab = tab;
            self.$router.push({ path: tab.path });
        },
        getMenuInfo: function () {
            let self = this;
            this.$request.get('menu').then((res) => {
                self.menuList = res.data.data.menuList;
                self.checkMenuSelected(self.menuList);
                self.mainMenu = self.menuList[0];
            });
        },
        checkMenuSelected($menuList) {},
    },
};
</script>
<template>
    <div class="menu">
        <div class="main-menu">
            <div class="menu-body">
                <div class="company-avatar"></div>
                <div class="menu-list">
                    <el-scrollbar>
                        <template v-for="(menu, m) in menuList" :key="m">
                            <div class="span-crust" :title="menu.content">
                                <div class="menu-span" @click="changeMenu(menu)" :class="{ selected: menu.selected }">
                                    <div class="icon">
                                        <component v-if="menu.icon" :is="menu.icon" style="width: 24px; height: 24px" />
                                    </div>
                                    <div class="title">{{ menu.name }}</div>
                                </div>
                            </div>
                        </template>
                    </el-scrollbar>
                </div>
            </div>
        </div>
        <div class="sub-menu">
            <div style="height: 100%">
                <div class="web-name">
                    <div class="name" v-if="subMenuWebNameState">七柠项目管理</div>
                    <div class="toggle-button" @click="updateInfo()">
                        <div class="icon">
                            <Fold v-if="subMenuOpenedState" style="width: 24px; height: 24px" />
                            <Expand v-else style="width: 24px; height: 24px" />
                        </div>
                        <div class="title">
                            {{ subMenuOpenedState ? '收起' : '展开' }}
                        </div>
                    </div>
                </div>
                <div class="menu-body">
                    <el-scrollbar>
                        <el-menu
                            :default-active="currentPath"
                            class="sub-menu-body"
                            :collapse="!subMenuOpenedState"
                            active-text-color="#ffd04b"
                            background-color="#545c64"
                            text-color="#fff"
                        >
                            <template v-if="mainMenu">
                                <a :href="menu.href" v-for="(menu, key) in mainMenu.subMenu" :key="key">
                                    <el-menu-item :index="menu.href">
                                        <el-icon>
                                            <component v-if="menu.icon" :is="menu.icon" />
                                            <!-- <document /> -->
                                        </el-icon>
                                        <template #title>{{ menu.name }}</template>
                                    </el-menu-item>
                                </a>
                            </template>
                        </el-menu>
                    </el-scrollbar>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.menu {
    display: flex;
}

.sub-menu-body {
    background-color: transparent;
    border: none;
}

.sub-menu-body:not(.el-menu--collapse) {
    width: 240px;
}

.sub-menu-body .el-menu-item.is-active {
    background-color: #434a50;
}

.main-menu {
    height: 100%;

    .menu-body {
        width: 68px;
        background-color: #2a2d31;
        height: 100%;
        overflow: hidden;
        width: 64px;

        &.opened {
            width: 240px;
        }

        .company-avatar {
            margin: 10px;
            width: 44px;
            height: 44px;
            background-color: #fff;
            border-radius: 5px;
        }

        .span-crust {
            padding: 8px;

            .menu-span {
                padding: 4px;
                width: 40px;
                height: 40px;
                border-radius: 4px;
                line-height: 40px;
                text-align: center;
                user-select: none;
                cursor: pointer;
                color: #fff;
                font-size: 12px;

                .icon {
                    height: 20px;
                    line-height: 20px;
                }

                .title {
                    height: 20px;
                    line-height: 24px;
                }
            }
        }

        .menu-list {
            height: calc(100% - 64px);
        }

        .menu-span.selected {
            background-color: #566270;
        }

        .menu-span:hover {
            background-color: #424b55;
        }
    }
}

.sub-menu {
    background-color: #545c64;
    height: 100%;

    a {
        color: initial;
        text-decoration: none;
    }

    .web-name {
        height: 64px;
        line-height: 64px;
        display: flex;
        position: relative;

        .name {
            margin-left: 20px;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            max-width: 156px;
        }

        .toggle-button {
            position: absolute;
            top: 0;
            right: 0;
            margin: 8px;
            padding: 4px;
            width: 40px;
            height: 40px;
            border-radius: 4px;
            line-height: 40px;
            text-align: center;
            user-select: none;
            cursor: pointer;
            color: #fff;
            font-size: 12px;

            .icon {
                height: 20px;
                line-height: 20px;
            }

            .title {
                height: 20px;
                line-height: 24px;
            }
        }
    }
}
</style>
