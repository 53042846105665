import axios from 'axios';
import cache from './cache';

let config = {
    // 基准 api 地址
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 8000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Auth-Token': '',
        'Refresh-Token': '',
    },
};
const service = axios.create(config);
// 请求拦截
service.interceptors.request.use(
    (config) => {
        let token = cache.get('authToken');
        if (token == undefined) {
            token = cache.get('refreshToken');
        }
        config.headers['Auth-Token'] = token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 请求响应
service.interceptors.response.use(
    (response) => {
        // 处理token
        try {
            if (response.data.token) {
                let token = response.data.token;
                cache.set('authToken', token.auth.token, token.auth.expireTime);
                cache.set('refreshToken', token.refresh.token, token.refresh.expireTime);
            }
        } catch (error) {}
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const requestPlugin = {
    install(app) {
        // 定义你的自定义方法
        app.config.globalProperties.$request = service;
    },
};
export default requestPlugin;
