<script>
import contact from './public-im/contact.vue';
import friend from './public-im/friend.vue';
import chat from './public-im/chat.vue';
import { Manager } from 'socket.io-client';
export default {
    name: 'public-im',
    components: {
        'im-contact': contact,
        'im-friend': friend,
        'im-chat': chat,
    },
    props: {
        msg: String,
    },
    data() {
        return {
            socket: null,
            showIm: false,
            fullScreen: false,
            unreadMessageCount: 100,
            popup: {
                width: 900,
                height: 640,
                top: 0,
                left: 0,
                style: '',
                'z-index': 999,
            },
        };
    },
    created: function () {
        let self = this;
        const manager = new Manager('ws://127.0.0.1:8081', {
            reconnectionDelayMax: 10000,
            query: {
                'my-key': 'my-value',
            },
        });

        self.socket = manager.socket('/', {
            auth: {
                token: '123',
            },
        });

        self.socket.on('connect', () => {
            const engine = self.socket.io.engine;
            console.log(engine.transport.name); // in most cases, prints "polling"
            console.log(112233);

            engine.once('upgrade', () => {
                // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
                console.log(engine.transport.name); // in most cases, prints "websocket"
            });

            engine.on('packet', ({ type, data }) => {
                // called for each packet received
            });

            engine.on('packetCreate', ({ type, data }) => {
                // called for each packet sent
            });

            engine.on('drain', () => {
                // called when the write buffer is drained
            });

            engine.on('close', (reason) => {
                // called when the underlying connection is closed
            });
        });

        // event listener for when localStorage is changed from another window
        addEventListener('storage', (event) => {
            if (event.key == 'im') {
                // im相关数据变更
            }
        });
    },
    methods: {
        showPopup() {
            this.showIm = true;
            // 计算居中位置
            this.$tools.deepCopy();
            this.popupStyle();
        },
        closePopup() {
            this.showIm = false;
        },
        fullScreenPopup() {
            let self = this;
            this.fullScreen = !this.fullScreen;
            setTimeout(() => {
                self.$refs.contactRef.updateScrollbar();
                self.$refs.chatRef.updateScrollbar();
            }, 200);
        },
        popupStyle() {
            this.popup.style =
                `width:${this.popup.width}px;` +
                `height:${this.popup.height}px; ` +
                `z-index: ${this.popup['z-index']}`;
        },
    },
};
</script>

<template>
    <div class="chat-icon" @click="showPopup">
        <el-badge :value="unreadMessageCount" :max="99" :hidden="unreadMessageCount <= 0">
            <el-icon size="28">
                <ChatLineSquare />
            </el-icon>
        </el-badge>
    </div>
    <div v-show="showIm" class="shade" style="z-index: 998"></div>
    <div v-show="showIm" class="popup" :style="popup.style" :class="{ 'full-screen': fullScreen }">
        <div class="popup-right-top-btn-group">
            <div class="icon-btn" :title="fullScreen ? '还原' : '全屏'" @click="fullScreenPopup">
                <el-icon size="16">
                    <component :is="fullScreen ? 'Minus' : 'FullScreen'" />
                </el-icon>
            </div>
            <div class="icon-btn" title="关闭" @click="closePopup">
                <el-icon size="20">
                    <Close />
                </el-icon>
            </div>
        </div>
        <div class="menu"></div>
        <div class="list">
            <im-contact msg="aksjge" ref="contactRef"></im-contact>
        </div>
        <div class="chat">
            <im-chat ref="chatRef"></im-chat>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.chat-icon {
    height: 44px;
    width: 44px;
    margin: 10px;
    display: grid;
    place-items: center;
}
.shade {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.popup {
    min-width: 550px;
    min-height: 330px;
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: #f2f2f2;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    display: flex;
    overflow: hidden;
    transition: width 0.2s, height 0.2s;

    .popup-right-top-btn-group {
        position: absolute;
        right: 0;
        top: 0;
        padding: 123;
        display: flex;

        .icon-btn {
            height: 32px;
            width: 32px;
            display: grid;
            place-items: center; /* 居中子元素 */
            cursor: pointer;
        }
    }

    .menu {
        width: 60px;
        background-color: #2e2e2e;
        flex-shrink: 0;
    }
    .list {
        width: 240px;
        flex-shrink: 0;
        border-right: 1px solid #ddd;
        background-color: #eee;
        height: inherit;
    }

    .chat {
        width: inherit;
        height: 100%;
    }
}

.popup.full-screen {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0 !important;
}
</style>
