<script>
import cache from '@/utils/cache';
export default {
    name: 'public-im-friend',
    components: {},
    props: {
        msg: String,
    },
    created() {},
    data() {
        return {};
    },
    created: function () {},
    methods: {},
};
</script>

<template></template>

<style lang="scss" scoped></style>
