<script>
import cache from '@/utils/cache';
export default {
    name: 'public-header',
    components: {},
    props: {
        msg: String,
    },
    created() {},
    data() {
        return {
            drawer: false,
            form: {
                avatar: '',
                name: '张三',
                mobile: '138****8000',
                email: 'lem***qq.com',
                description: '',
            },
        };
    },
    created: function () {},
    methods: {
        logout() {
            cache.del('authToken');
            cache.del('refreshToken');
            window.location.href = '/#/login';
        },
        onSaveUserInfo() {
            let self = this;
        },
    },
};
</script>

<template>
    <div class="main-title">
        <div class="right-body">
            <div class="user-info">
                <public-im></public-im>
                <el-dropdown>
                    <span class="el-dropdown-link">
                        <div class="avatar"></div>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="drawer = true">
                                <el-icon :size="16">
                                    <Setting />
                                </el-icon>
                                个人设置
                            </el-dropdown-item>
                            <el-dropdown-item @click="logout()">
                                <el-icon :size="16">
                                    <Right />
                                </el-icon>
                                退出登录
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
    </div>

    <el-drawer v-model="drawer" title="个人设置" :with-header="true" :size="400">
        <el-form :model="form" label-width="80px">
            <el-form-item label="头像">
                <el-input disabled v-model="form.avatar" />
            </el-form-item>
            <el-form-item label="姓名">
                <el-input disabled v-model="form.name" />
            </el-form-item>
            <el-form-item label="手机号">
                <el-input disabled v-model="form.mobile" />
            </el-form-item>
            <el-form-item label="邮箱">
                <el-input disabled v-model="form.email" />
            </el-form-item>
            <el-form-item label="跟人介绍">
                <el-input v-model="form.description" type="textarea" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSaveUserInfo">Create</el-button>
                <el-button>Cancel</el-button>
            </el-form-item>
        </el-form>
    </el-drawer>
</template>

<style lang="scss" scoped>
.main-title {
    height: 64px;
    width: 100%;
    background: #fff;

    .right-body {
        height: 64px;
        display: flex;
        justify-content: flex-end;

        .user-info {
            display: flex;
            cursor: pointer;

            .avatar {
                margin: 10px;
                width: 44px;
                height: 44px;
                background-color: #27282c;
                border-radius: 5px;
            }
        }
    }
}
</style>
