import { createStore } from 'vuex';

export default createStore({
    state: {
        chatPopupState: true,
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {},
});
