<script>
import cache from '@/utils/cache';
export default {
    name: 'public-im-message-card',
    props: {
        message: Object,
    },
    created() {},
    data() {
        return {};
    },
    created: function () {},
    mounted: function () {},
    methods: {},
};
</script>

<template>
    <div class="body">
        <template v-if="message.card.type == 'mini-pro'"> </template>
        <template v-else>
            <div class="general-card"></div>
        </template>
    </div>
</template>

<style lang="scss" scoped></style>
