import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import components from './components';
import modules from './modules';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import '@/assets/scss/main.scss';
import request from './utils/request';
import tools from './utils/tools';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, far, fab);

const app = createApp(App);
app.use(store);
app.use(request);
app.use(tools);
app.use(router);
app.use(VueCookies);
app.use(ElementPlus, {
    locale: zhCn,
});
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
for (const [key, module] of Object.entries(modules)) {
    app.component(key, module);
}
/* add font awesome icon component */
app.component('font-awesome-icon', FontAwesomeIcon);
app.config.productionTip = false;
// 批量注册组件
Object.keys(components).forEach((name) => {
    app.component(name, components[name]);
});

app.mount('#app');
