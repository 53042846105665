// index.js
const files = require.context('./', false, /\.vue$/);
const modules = {};

files.keys().forEach((key) => {
    const moduleConfig = files(key);
    const moduleName = moduleConfig.default.name;

    modules[moduleName] = moduleConfig.default || moduleConfig;
});

export default modules;
