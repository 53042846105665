// index.js
const files = require.context('./', false, /\.vue$/);
const components = {};

files.keys().forEach((key) => {
    const componentConfig = files(key);
    const componentName = componentConfig.default.name;

    components[componentName] = componentConfig.default || componentConfig;
});

export default components;
