function set(key, value, expire) {
    let data = {
        value: value,
        expire: expire,
    };
    return localStorage.setItem('cache.' + key, JSON.stringify(data));
}

function get(key) {
    let json = localStorage.getItem('cache.' + key);
    if (json) {
        let data = JSON.parse(json);
        // 获取当前时间戳
        let date = new Date();
        let timestamp = Date.parse(date) / 1000;
        if (data.expire >= timestamp || data.expire === -1) {
            return data.value;
        }
    }
    return undefined;
}

function del(key) {
    return localStorage.removeItem('cache.' + key);
}

const cache = {
    get,
    set,
    del,
};

export default cache;
