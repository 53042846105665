import { createRouter, createWebHashHistory } from 'vue-router';
import cache from '@/utils/cache';

const routes = [
    {
        path: '/login',
        name: '',
        component: () => import('@/views/system/login.vue'),
        meta: {
            title: '登录',
            noLogin: true,
        },
    },
    {
        path: '/loading',
        name: '',
        component: () => import('@/views/system/loading.vue'),
        meta: {
            title: '加载中...',
            noLogin: true,
        },
    },
    {
        path: '/forget-password',
        name: '',
        component: () => import('@/views/system/forget-password.vue'),
        meta: {
            title: '找回密码',
            noLogin: true,
        },
    },
    {
        path: '/',
        name: '',
        component: () => import('@/views/home/workspace.vue'),
        meta: {
            title: '控制台',
        },
    },
    {
        path: '/database/list',
        name: '',
        component: () => import('@/views/database/list.vue'),
        meta: {
            title: '数据库列表',
        },
    },
    {
        path: '/tools/time',
        name: '',
        component: () => import('@/views/tools/time.vue'),
        meta: {
            title: '数据库列表',
        },
    },
    {
        path: '/tools/json',
        name: '',
        component: () => import('@/views/tools/json.vue'),
        meta: {
            title: 'json解析',
        },
    },
    {
        path: '/tools/query-bind',
        name: '',
        component: () => import('@/views/tools/query-bind.vue'),
        meta: {
            title: '请求绑定',
        },
    },
    {
        path: '/template/table',
        name: '',
        component: () => import('@/views/template/table.vue'),
        meta: {
            title: '请求解绑',
        },
    },
    {
        path: '/404',
        name: '',
        component: () => import('@/views/system/not-found.vue'),
        meta: {
            title: '404 page not found',
        },
    },
    {
        path: '/:pathMatch(.*)',
        redirect: '/404',
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.afterEach((to, from, next) => {
    // 判断登录状态
});

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});

export default router;
