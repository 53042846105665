<script>
import cache from './utils/cache';
export default {
    name: 'App',
    data() {
        return {
            activeViewPath: '/loading',
            separatePageArray: ['/login', '/loading', '/not-found', '/404', '/forget-password'],
        };
    },
    beforeCreate() {
        let token = cache.get('authToken');
        if (token == undefined) {
            token = cache.get('refreshToken');
        }
        if (token == undefined) {
            this.$router.push('/login');
            this.activeViewPath = '/login';

            console.log('no token');
        }
    },
    created: function () {
        // 隐藏加载层
        let loading = document.getElementById('main-loading-animation');
        loading.style.display = 'none';
        document.onkeydown = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 's') {
                // 阻止默认事件
                e.preventDefault();
            }
        };
    },
    mounted: function () {},
    methods: {},
    watch: {
        $route(to, from) {
            this.activeViewPath = to.path;
        },
    },
};
</script>

<template>
    <template v-if="separatePageArray.includes(activeViewPath)">
        <router-view></router-view>
    </template>
    <template v-else>
        <public-menu></public-menu>
        <div class="main-body">
            <public-header></public-header>
            <div class="page-body">
                <el-scrollbar>
                    <router-view></router-view>
                </el-scrollbar>
            </div>
        </div>
    </template>
</template>

<style lang="scss" scoped>
.main-body {
    height: 100%;
    width: 100%;
    background-color: #f2f2f2;
    overflow: auto;

    .page-body {
        width: 100%;
        height: calc(100% - 64px);
    }
}
</style>
