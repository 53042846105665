<script>
import cache from '@/utils/cache';
import card from '@/modules/public-im/card-message/card.vue';
export default {
    name: 'public-im-chat',
    components: {
        card,
    },
    props: {
        msg: String,
    },
    data() {
        return {
            contact: {
                id: 0,
                name: '张三',
            },
            chat: {
                textarea: '',
            },
            list: [],
            showSendFileState: false,
            sendFileList: [],
            textareaDragenterLock: false,
            textareaDragenterShadeState: false,
        };
    },
    created: function () {
        let self = this;
    },
    mounted: function () {
        let self = this;
        let textareaRef = this.$refs.textarea;
        textareaRef.addEventListener('keydown', function (event) {
            // 检查按下的键是否是回车键 (Enter键的键码是13)
            if (event.key === 'Enter') {
                // 阻止回车键事件的默认行为
                event.preventDefault();
            }
        });
        textareaRef.addEventListener('paste', function (event) {
            // addEventListener：添加粘贴事件监听
            let files = event.clipboardData.files; // 获取剪贴板图片
            if (files.length == 0) {
                return;
            }
            self.showSendFile(files);
        });
        self.showDialogueInfo();
        self.list = [
            {
                isSelf: true,
                text: '利用input输入自带的方法paste。根据文件类型来判定是不是图片，如果是图片就把文件上传到后端',
                type: 'text',
            },
            {
                isSelf: false,
                text: '需求背景是用\r\n户粘贴截图或者\r\n复制的图片后，需要自动上传文件后端。',
                type: 'text',
            },
            {
                isSelf: false,
                text: '需求背景是用户粘贴截图或者复制的图片后，需要自动上传文件后端。',
                type: 'text',
            },
            {
                isSelf: true,
                text: '需求背景是用户粘贴截图或者复制的图片后，需要自动上传文件后端。',
                type: 'text',
            },
            {
                isSelf: true,
                text: '利用input输入自带的方法paste。根据文件类型来判定是不是图片，如果是图片就把文件上传到后端',
                type: 'text',
            },
            {
                isSelf: false,
                text: '需求背景是用\r\n户粘贴截图或者\r\n复制的图片后，需要自动上传文件后端。',
                type: 'text',
            },
            {
                isSelf: true,
                text: '利用input输入自带的方法paste。根据文件类型来判定是不是图片，如果是图片就把文件上传到后端',
                type: 'text',
            },
            {
                isSelf: false,
                text: '需求背景是用\r\n户粘贴截图或者\r\n复制的图片后，需要自动上传文件后端。',
                type: 'text',
            },
            {
                isSelf: false,
                text: '需求背景是用户粘贴截图或者复制的图片后，需要自动上传文件后端。',
                type: 'text',
            },
            {
                isSelf: true,
                text: '需求背景是用户粘贴截图或者复制的图片后，需要自动上传文件后端。',
                type: 'text',
            },
            {
                isSelf: true,
                text: '利用input输入自带的方法paste。根据文件类型来判定是不是图片，如果是图片就把文件上传到后端',
                type: 'text',
            },
            {
                isSelf: false,
                text: '需求背景是用\r\n户粘贴截图或者\r\n复制的图片后，需要自动上传文件后端。',
                type: 'text',
            },
        ];
    },
    methods: {
        showDialogueInfo() {
            let self = this;
            self.chatScrollbarBottom();
        },
        /**
         * 文件-约束、读取
         */
        readFile(files) {
            let self = this;
            self.sendFileList = [];
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                let fileType = file.type;
                let fileName = file.name;
                let fileSize = self.$tools.formatBytesSize(file.size);
                let reader = new FileReader();
                let fileClass = 'file';
                reader.onload = function (e) {
                    let base64 = e.target.result;
                    self.sendFileList.push({
                        src: base64,
                        name: fileName,
                        type: fileType,
                        size: fileSize,
                        class: fileClass,
                    });
                };
                reader.readAsDataURL(file);
            }
        },

        /**
         * 文件拖拽dragenter进入
         */
        textareaDragenter() {
            console.log('handlePictureBoxDragenter==');
            // 这里传入一个10ms的延迟锁，让dragleave在enter延迟后触发
            this.textareaDragenterLock = true;
            setTimeout(() => {
                this.textareaDragenterLock = false;
            }, 10);
            this.textareaDragenterShadeState = true;
        },

        /**
         * 文件拖拽dragleave离开
         */
        textareaDragleave() {
            console.log('handlePictureBoxDragleave===');
            if (this.textareaDragenterLock) return;
            this.dropType = false;
            this.textareaDragenterShadeState = false;
        },

        /**
         * 文件拖拽drop落下
         */
        textareaDrop(e) {
            this.dropType = false;
            this.textareaDragenterShadeState = false;
            // 判断file是文件夹还是文件
            let isDirectory = e.dataTransfer.items[0].webkitGetAsEntry().isDirectory;
            let fileList = e.dataTransfer.files;
            if (isDirectory) {
                this.$message({
                    message: `上传${this.fileType}文件`,
                    type: 'warning',
                });
                return;
            }
            this.showSendFile(fileList);
        },
        showSendFile: function (files) {
            let self = this;
            self.showSendFileState = true;
            self.readFile(files);
        },
        updateScrollbar() {
            let self = this;
            self.$nextTick(() => {
                // self.$refs.chatScrollbarRef.update();
            });
        },
        sendMessage(e) {
            let self = this;
            if (e.ctrlKey && e.keyCode === 13) {
                // 获取光标位置
                var textarea = this.$refs.textarea;
                let currentPosition = textarea.selectionStart;
                let newTextToInsert = '\r\n';
                // 获取文本框的当前内容
                var currentText = textarea.value;
                // 将要插入的文本插入到光标位置
                var newText =
                    currentText.slice(0, currentPosition) + newTextToInsert + currentText.slice(currentPosition);
                // 更新文本框的内容
                textarea.value = newText; // 必须保留 双向绑定有延时 如果不直接设置 会导致光标跳转至最后
                self.chat.textarea = newText; // 直接赋值textarea的value 不会触发v-model更新, 需要手动设置
                // 将光标移动到插入文本的末尾
                textarea.selectionStart = currentPosition + 1;
                textarea.selectionEnd = currentPosition + 1;
                // 让文本框获得焦点
                textarea.focus();
            } else {
                if (self.chat.textarea.trim() == '') return;
                console.log(self.chat.textarea);
                self.list.push({
                    isSelf: true,
                    text: self.chat.textarea,
                    type: 'text',
                });
                // self.socket.send(
                //     JSON.stringify({
                //         isSelf: true,
                //         text: self.chat.textarea,
                //         type: 'text',
                //     })
                // );
                self.chat.textarea = '';
                self.$nextTick(() => {
                    self.chatScrollbarBottom();
                });
            }
        },
        // 滑动聊天窗滚动条到最底部
        chatScrollbarBottom() {
            let self = this;
            // const container = self.$refs['chatScrollbarRef'].$el.querySelector('.el-scrollbar__wrap');
            // self.$refs['chatScrollbarRef'].setScrollTop(container.scrollHeight);
        },
    },
};
</script>

<template>
    <div class="body">
        <div class="header">
            <div class="contact-name">{{ contact.name }}</div>
            <div class="contact-btn-group">
                <div class="icon-btn" title="置顶">
                    <el-icon size="18">
                        <Top />
                    </el-icon>
                </div>
                <div class="icon-btn" title="关注">
                    <el-icon size="18">
                        <Star />
                    </el-icon>
                </div>
                <div class="icon-btn" title="删除" @click="closePopup">
                    <el-icon size="16">
                        <Delete />
                    </el-icon>
                </div>
                <div class="icon-btn" title="更多">
                    <el-icon size="16">
                        <More />
                    </el-icon>
                </div>
            </div>
        </div>
        <div class="chat">
            <el-scrollbar ref="chatScrollbarRef">
                <template v-for="(message, m) in list" :key="m">
                    <div class="message clearfix" :class="{ self: message.isSelf }">
                        <div class="avatar"></div>
                        <template v-if="message.type == 'text'">
                            <div class="bubble" v-html="message.text"></div>
                        </template>
                        <template v-if="message.type == 'card'">
                            <card :message="message"></card>
                        </template>
                    </div>
                </template>
            </el-scrollbar>
        </div>
        <div
            class="footer"
            @dragenter.stop.prevent="textareaDragenter"
            @dragover.stop.prevent=""
            @dragleave.stop.prevent="textareaDragleave"
            @drop.stop.prevent="textareaDrop"
        >
            <div class="tools-btn-group">
                <div class="icon-btn">
                    <el-icon size="20">
                        <Comment />
                    </el-icon>
                </div>
                <div class="icon-btn">
                    <el-icon size="20">
                        <PictureFilled />
                    </el-icon>
                </div>
                <div class="icon-btn">
                    <el-icon size="20">
                        <FolderAdd />
                    </el-icon>
                </div>
            </div>
            <textarea
                class="enter-textarea"
                ref="textarea"
                v-model="chat.textarea"
                @keyup.enter="sendMessage"
            ></textarea>
            <div class="enter-btn-group">
                <div class="btn-send" @click="sendMessage">发送(send)</div>
            </div>
            <div class="shade" v-show="textareaDragenterShadeState"></div>
        </div>
    </div>

    <el-dialog
        v-model="showSendFileState"
        title="发送给"
        width="300"
        align-center
        append-to-body
        class="body-padding-small"
    >
        <div class="send-file-popup">
            <div class="user-info">
                <div class="avatar"></div>
                <div class="name">张三</div>
            </div>
            <el-scrollbar max-height="240px" class="send-file-list">
                <div class="file" v-for="(file, index) in sendFileList">
                    <div>
                        <el-image
                            style="max-width: 80px; max-height: 80px"
                            :src="file.src"
                            :zoom-rate="1.2"
                            :max-scale="7"
                            :min-scale="0.2"
                            :preview-src-list="srcList"
                            :initial-index="4"
                            fit="cover"
                        />
                    </div>
                    <div>
                        <div>{{ file.name }}</div>
                        <div>{{ file.size }}</div>
                    </div>
                    <div>删除</div>
                </div>
            </el-scrollbar>
        </div>

        <template #footer>
            <span class="dialog-footer">
                <el-button @click="showSendFileState = false">取消</el-button>
                <el-button type="primary" @click="showSendFileState = false"> 发送 </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<style lang="scss" scoped>
.body {
    height: 100%;
    display: flex; /* 使用 Flexbox 布局 */
    flex-direction: column; /* 纵向排列 */

    .header {
        height: 64px;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: space-between;

        .contact-name {
            height: 64px;
            line-height: 64px;
            padding: 0 25px;
            font-size: 18px;
            font-weight: 500;
        }

        .contact-btn-group {
            padding-top: 32px;
            height: 32px;
            display: flex;
            justify-content: flex-end;

            .icon-btn {
                height: 32px;
                width: 32px;
                display: grid;
                place-items: center; /* 居中子元素 */
                cursor: pointer;
            }
        }
    }

    .chat {
        flex: 1;
        overflow: auto; /* 如果内容溢出，显示滚动条 */
        border-bottom: 1px solid #ddd;

        .message:first-child {
            padding-top: 15px;
        }
        .message {
            width: 100%;
            padding: 0 30px;
            padding-bottom: 15px;
            box-sizing: border-box;

            .avatar {
                height: 36px;
                width: 36px;
                background-color: #333;
                border-radius: 3px;
                margin-right: 10px;
                float: left;
            }

            .bubble {
                line-height: 20px;
                padding: 8px 12px;
                background-color: #fff;
                float: left;
                border-radius: 5px;
                position: relative;
                max-width: 60%;
                word-wrap: break-word;
                white-space: break-spaces;
            }

            .bubble::before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border-right: 8px solid transparent;
                border-top: 8px solid #fff;
                transform: rotate(-45deg);
                top: 14px;
                left: -4px;
            }
        }

        .message.self {
            width: 100%;

            .avatar {
                float: right;
                margin-left: 10px;
                margin-right: 0;
            }

            .bubble {
                float: right;
                background-color: #95ec69;
            }

            .bubble::before {
                border-top: 8px solid #95ec69;
                transform: rotate(135deg);
                top: 14px;
                left: auto;
                right: -4px;
            }
        }

        .clearfix::after {
            content: ' ';
            display: block;
            clear: both; /* 清除浮动 */
        }
    }
    .footer {
        height: 214px;
        position: relative;

        .tools-btn-group {
            height: 36px;
            padding: 0 10px;
            padding-top: 6px;
            display: flex;
            align-items: center;

            .icon-btn {
                height: 36px;
                width: 36px;
                display: grid;
                place-items: center; /* 居中子元素 */
                cursor: pointer;
            }
        }

        .enter-textarea {
            border: 0;
            background-color: #f2f2f2;
            resize: none;
            width: 100%;
            height: 120px;
            outline: none;
            font-size: 14px;
            padding: 0 20px;
            box-sizing: border-box;
            display: block;
            line-height: 24px;
        }

        .shade {
            width: 100%;
            height: 214px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .enter-btn-group {
            height: 44px;
            padding: 10px 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-end;
            .btn-send {
                height: 32px;
                width: 120px;
                text-align: center;
                line-height: 32px;
                background-color: #ddd;
                border-radius: 4px;
                color: #01a03e;
                cursor: pointer;
            }
        }
    }
}
.send-file-popup {
    .user-info {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;

        .avatar {
            height: 36px;
            width: 36px;
            background-color: #333;
            border-radius: 3px;
            margin-right: 10px;
        }
    }
    .send-file-list {
        margin-top: 10px;
        .file {
            display: flex;
        }
    }
}
</style>
